import React from "react";
import { graphql } from 'gatsby'
import { Link } from "gatsby";
import ReactMarkdown from "react-markdown";
import { createHubSpotForm } from "../utils/common";
import { HelmetDatoCms } from "gatsby-source-datocms";
import Notification from "../components/header/notification";
import Layout from "../layouts";

export default ({
  data: {
    datoCmsResource: {
      id,
      title,
      description,
      hubspotFormHeading,
      hubspotFormDescription,
      hubspotFormImage,
      hubspotFormId,
      notificationPage,
      seoMetaTags
    },
    allDatoCmsNotification: { edges: notifications }
  }
}) => {
  createHubSpotForm({
    formId: hubspotFormId,
    targetId: "#resourceHubspotForm",
    css: ""
  });
  return (
    <Layout>
      <HelmetDatoCms seo={seoMetaTags} />
      <Notification
        notifications={notifications}
        linkedPage={notificationPage}
      />
      <div className="navbar">
        <div className="container grid valign landing">
          <Link to="/" className="logo-nav w-inline-block" />
        </div>
      </div>
      <header className="white-section event">
        <div className="container grid">
          <div className="_2cols-box">
            <h1 className="h-nofixedwidth">{title}</h1>
            <div className="sm-spacing" />
            <div>
              <ReactMarkdown source={description} escapeHtml={false} />
            </div>
          </div>
          <div className="webinar-form-container">
            <h4 className="h4" style={{ textAlign: "center" }}>
              {hubspotFormHeading}
            </h4>
            <p style={{ textAlign: "center" }}>{hubspotFormDescription}</p>
            <img src={hubspotFormImage.url} alt={hubspotFormImage.alt} />
            <div className="sm-spacing" />
            <div className="w-embed w-script">
              <div id="resourceHubspotForm" />
            </div>
          </div>
        </div>
      </header>
    </Layout>
  );
};

export const query = graphql`
  query ResourceQuery($slug: String!) {
    datoCmsResource(slug: { eq: $slug }) {
      id
      title
      description
      hubspotFormHeading
      hubspotFormDescription
      hubspotFormImage {
        alt
        url
      }
      hubspotFormId
      notificationPage {
        id
        page
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    allDatoCmsNotification {
      edges {
        node {
          id
          content
          show
          weeks
          notificationPosition
          linkToPage {
            id
            page
          }
          backgroundColor {
            hex
          }
        }
      }
    }
  }
`;
