import React from "react";
import { graphql, StaticQuery } from "gatsby";
import "../styles/css/normalize.css";
import "../styles/css/webflow.css";
import "../styles/css/nugit.css";
import "../styles/css/custom.css";
import { HelmetDatoCms } from "gatsby-source-datocms";
import { Link } from "gatsby";
import Footer from "../components/footer/footer";
import Header from "../components/header/header";
import Schema from "../components/common/schema";
import TrackingScript from "../components/tracking-script";

export const query = graphql`
  query LayoutQuery {
    datoCmsSite {
      globalSeo {
        siteName
        fallbackSeo {
          title
          description
          image {
            url
          }
        }
      }
      faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
    datoCmsSubscribeForm {
      heading
      description
      hubspotFormId
    }
    allDatoCmsSocialProfile(sort: { fields: [position], order: ASC }) {
      edges {
        node {
          url
          image {
            alt
            url
          }
        }
      }
    }
    allDatoCmsFooterLink(
      filter: { root: { eq: true } }
      sort: { fields: [position], order: ASC }
    ) {
      edges {
        node {
          id
          title
          cta
          position
          treeChildren {
            id
            title
            cta
            position
          }
        }
      }
    }
    datoCmsGlobalConfiguration {
      vimeoAnalyticsKey
      googleMapsKey
      schema {
        ... on DatoCmsSchemaDetail {
          id
          jsonSchema
        }
      }
    }
    allDatoCmsTopNavigation(
      filter: { root: { eq: true } }
      sort: { fields: [position], order: ASC }
    ) {
      edges {
        node {
          position
          id
          title
          topNavLink
          treeChildren {
            position
            id
            title
            topNavLink
          }
        }
      }
    }
  }
`;

export default ({ children }) => {
  const render = (data) => {
    let {
      datoCmsGlobalConfiguration,
      allDatoCmsTopNavigation: { edges: topNavItems },
    } = data;
    let { schema } = datoCmsGlobalConfiguration;

    return (
      <React.Fragment>
        <HelmetDatoCms favicon={data.datoCmsSite.faviconMetaTags} />
        <Schema data={schema} />
        <Header topNav={topNavItems} />
        {children}
        <section id="demo" className="white-section centered full-demo">
          <div className="container demo">
            <div className="sm-spacing _30" />
            <iframe
              border="0px"
              height="800px"
              src="https://heyflow.id/website-lead"
              style={{ border: 'none' }}
              width="100%"
            />
          </div>
        </section>
        <Footer data={data} />
        <TrackingScript data={datoCmsGlobalConfiguration} />
      </React.Fragment>
    );
  };

  return (
    <StaticQuery query={query} render={(data) => render(data)}></StaticQuery>
  );
};
